import { GoogleAnalyticsPlugin } from '@app/analytics/ga';
import { isBrowser } from '@app/utils/utils';
import { useMemo } from 'react';

import type { ChapterItem, ChapterNovelInfo } from '@app/_proto/Protos/chapters';
import type { NovelItem } from '@app/_proto/Protos/novels';
import { PosthogPlugin } from '@app/analytics/posthog';
import { GTMPlugin } from './gtm';
import type {
    AnalyticsCheckoutItem,
    AnalyticsPayment,
    AnalyticsPluginDeclaration,
    AnalyticsPluginInstance,
    AnalyticsUserInfo,
} from './types';

function makePlugin<N extends string, C, O>(
    plugin: AnalyticsPluginDeclaration<N, C, O>
): AnalyticsPluginDeclaration<N, C, O> {
    return plugin;
}

const Plugins = {
    posthog: makePlugin({
        name: 'posthog',
        plugin: new PosthogPlugin(),
        enabled: !!import.meta.env?.VITE_REACT_APP_PUBLIC_POSTHOG_KEY,
        options: {
            token: import.meta.env?.VITE_REACT_APP_PUBLIC_POSTHOG_KEY,
            api_host: import.meta.env?.VITE_REACT_APP_PUBLIC_POSTHOG_HOST,
            autocapture: false,
        },
    }),
    gtm: makePlugin({
        name: 'gtm',
        plugin: new GTMPlugin(),
        enabled: !!import.meta.env?.VITE_REACT_APP_GTM_ID,
        options: {
            tagId: import.meta.env?.VITE_REACT_APP_GTM_ID,
        },
    }),
    ga: makePlugin({
        name: 'ga',
        plugin: new GoogleAnalyticsPlugin(),
        enabled: !!import.meta.env?.VITE_REACT_APP_GA_ID,
        options: {
            id: import.meta.env?.VITE_REACT_APP_GA_ID,
        },
    }),
};

type PluginNames = keyof typeof Plugins;

type DoAnalyticsActionOptions = {
    excludePlugins: PluginNames[];
};

const analytics = {
    enabled: isBrowser(), //process.env.PROD,
    plugins: Plugins,
    getPlugin<N extends PluginNames>(name: N) {
        return analytics.plugins[name];
    },
};

export const getAnalytics = () => analytics;

export const doAnalyticsAction = (
    action: (instance: AnalyticsPluginInstance<any, any>) => void,
    options?: DoAnalyticsActionOptions
) => {
    if (!analytics.enabled) {
        return;
    }

    const plugins = Object.values(analytics.plugins);
    const excludePlugins = new Set(options?.excludePlugins || []);

    for (const { plugin } of plugins.filter(f => f.enabled && !excludePlugins.has(f.name))) {
        action(plugin);
    }
};

export const initAnalytics = () => {
    if (!analytics.enabled) {
        return false;
    }

    const plugins = Object.values(analytics.plugins);

    for (const decl of plugins.filter(f => f.enabled)) {
        decl.plugin.onReady(instance => decl.onReady?.(instance));
        decl.plugin.init(decl.options);
    }
    return true;
};

export const enableAnalyticsPlugin = (name: PluginNames) => {
    const dec = analytics.getPlugin(name);

    dec.enabled = true;
    dec.plugin.onReady(instance => dec.onReady?.(instance));
    dec.plugin.init(dec.options);
};

export const disableAnalyticsPlugin = (name: PluginNames) => {
    const dec = analytics.getPlugin(name);

    dec.enabled = false;
};

export const setAnalyticsUser = async (userId: string | null, info: AnalyticsUserInfo | null) => {
    doAnalyticsAction(a => a.setUser?.(userId, info));
};

export const normalizeProperties = properties => {
    if (!properties) {
        return properties;
    }
    const newProps = { ...properties };
    if (properties['Completion Status'] !== undefined) {
        switch (properties['Completion Status']) {
            case 0:
                newProps['Completion Status'] = 'Completed';
                break;
            case 1:
                newProps['Completion Status'] = 'Ongoing';
                break;
            case 2:
                newProps['Completion Status'] = 'Hiatus';
                break;
        }
    }

    return newProps;
};

export const logAnalyticsEvent = (
    eventName: string,
    properties?: Record<string, string | string[] | number | number[] | null | undefined>,
    options?: DoAnalyticsActionOptions // eslint-disable-line @typescript-eslint/no-unused-vars
) => {
    // normalizeProperties
    const normalizedProperties = normalizeProperties(properties);

    // console.info('logAnalyticsEvent', eventName, normalizedProperties, options);
    // doAnalyticsAction(a => a.trackEvent(eventName, properties), options);
    // Exclude GTM until GTM tags ready
    doAnalyticsAction(a => a.trackEvent(eventName, normalizedProperties), { excludePlugins: ['gtm', 'ga'] });
};

export const logEvent = (
    eventName: string,
    properties?: Record<string, string | string[] | number | number[] | null>
) => {
    doAnalyticsAction(a => a.trackEvent(eventName, properties));
};

export const logGtmEvent = (
    eventName: string,
    properties?: Record<string, string | string[] | number | number[] | null>
) => {
    const plugin = analytics.getPlugin('gtm')?.plugin as GTMPlugin | undefined;

    if (!plugin) {
        return;
    }

    plugin.trackEvent(eventName, properties);
};

export const logGAEvent = (
    eventName: string,
    properties?: Record<string, string | string[] | number | number[] | unknown | null>
) => {
    const plugin = analytics.getPlugin('ga')?.plugin as GoogleAnalyticsPlugin | undefined;

    if (!plugin) {
        return;
    }

    plugin.execCommand('event', eventName, properties);
};

declare global {
    interface Window {
        fbq?: (
            command: string,
            eventName: string,
            properties?: Record<string, string | string[] | number | number[] | unknown | null>
        ) => void;
    }
}

export const logMetaPixelEvent = (
    eventName: string,
    properties?: Record<string, string | string[] | number | number[] | unknown | null>
) => {
    if (typeof window.fbq === 'undefined') {
        return;
    }

    window.fbq('track', eventName, properties);
};

export const logSelectSeries = (novel: NovelItem | ChapterNovelInfo) => {
    logGAEvent('select_item', {
        item_list_id: 'series',
        item_list_name: 'Series',
        items: [
            {
                item_id: novel.id.toString(),
                item_name: novel.name,
                item_slug: novel.slug,
            },
        ],
    });

    logMetaPixelEvent('ViewContent', {
        content_ids: [novel.id.toString()],
        content_name: novel.name,
        content_type: 'series',
    });
};

export const logSelectChapter = (novel: NovelItem | ChapterNovelInfo, chapter: ChapterItem | undefined) => {
    logGAEvent('start_reading', {
        novel_id: novel.id.toString(),
        novel_name: novel.name,
        novel_slug: novel.slug,
        chapter_id: chapter?.entityId,
        chapter_name: chapter?.name,
        chapter_slug: chapter?.slug,
    });

    logMetaPixelEvent('StartTrial', {
        content_category: 'chapter',
        content_ids: [chapter?.entityId],
        content_name: chapter?.name,
        novel_name: novel.name,
    });
};

export const logCheckout = (price: number, items: AnalyticsCheckoutItem[]) => {
    logGAEvent('begin_checkout', {
        value: price,
        currency: 'USD',
        items: items.map(item => ({
            item_id: item.id.toString(),
            item_name: item.name,
            item_category: item.category,
            item_brand: item.brand,
            quantity: item.quantity,
            price: item.price,
            discount: item.discount,
        })),
    });

    logMetaPixelEvent('InitiateCheckout', {
        value: price,
        currency: 'USD',
        content_type: 'product',
        content_name: items.map(item => item.brand ?? item.category ?? item.name).join(', '),
        content_ids: items.map(item => item.id.toString()),
        contents: items.map(item => ({
            id: item.id.toString(),
            quantity: item.quantity,
        })),
        num_items: items.length,
    });
};

export const logPurchase = (payment: AnalyticsPayment) => {
    doAnalyticsAction(a => a.trackPayment(payment));

    logGAEvent('purchase', {
        currency: 'USD',
        value: payment.price,
        transaction_id: payment.transactionId,
        items: payment.items.map(item => ({
            item_id: item.id.toString(),
            item_name: item.name,
            item_category: item.category,
            item_brand: item.brand,
            quantity: item.quantity,
            price: item.price,
            discount: item.discount,
        })),
    });

    logMetaPixelEvent('Purchase', {
        value: payment.price,
        currency: 'USD',
        content_type: 'product',
        content_name: payment.items.map(item => item.brand ?? item.category ?? item.name).join(', '),
        contents: payment.items.map(item => ({
            id: item.id.toString(),
            quantity: item.quantity,
        })),
        num_items: payment.items.length,
    });
};

export const useAnalytics = () => {
    return useMemo(
        () => ({
            logEvent: logAnalyticsEvent,
            logPayment: logPurchase,
            enablePlugin: enableAnalyticsPlugin,
            disablePlugin: disableAnalyticsPlugin,
            doAction: doAnalyticsAction,
        }),
        []
    );
};
