import { omit } from 'lodash-es';

type Params = {
    el: HTMLElement | undefined;
    reverseMerge?: boolean;
};
export const extractPosthogParams = ({ el, reverseMerge }: Params): object => {
    if (!el) return {};
    let posthogParamEl = el;
    const posthogParams = {};
    for (let i = 0; i < 10; i++) {
        const paramsEl = posthogParamEl.closest('[data-posthog-params]');
        if (!paramsEl || !paramsEl.parentElement) break;
        const params = paramsEl.getAttribute('data-posthog-params');
        if (reverseMerge) {
            Object.assign(posthogParams, omit(JSON.parse(params ?? '{}'), Object.keys(posthogParams)));
        } else {
            Object.assign(posthogParams, JSON.parse(params ?? '{}'));
        }

        posthogParamEl = paramsEl.parentElement;
    }
    return posthogParams;
};
