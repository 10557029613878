import Paging from '@app/components/paging';
import UserNotification from '@app/components/user-notifications/user-notification';
import Chips from '@app/domains/common/components/Chips';
import ChipsSwiper from '@app/domains/common/components/ChipsSwiper';
import { useAuth } from '@app/libs/auth';
import { useAllNotifications, useMarkAllRead } from '@app/libs/notifications';
import { batch } from '@app/libs/utils';
import { Button, Grid, Skeleton, type SkeletonProps, Typography } from '@mui/material';
import clsx from 'clsx';
import * as React from 'react';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { SwiperSlide } from 'swiper/react';

//#region : Global constants and types
const PlaceholderSkeleton = React.memo(function PlacehlderSkeleton({ className, ...rest }: SkeletonProps) {
    return <Skeleton className={clsx('bg-[#ffffff] dark:bg-[#2a2a2a]', className)} {...rest} />;
});

const PlaceholderComponent = React.memo(function PlaceholderComponent({ count = 4 }: { count?: number }) {
    return (
        <div className="flex flex-col">
            {Array(count)
                .fill(null)
                .map((_, idx) => (
                    <div
                        key={idx}
                        className="border-t border-[#f0f0f0] px-[15px] py-[12px] dark:border-[#444] md:rounded-[10px]"
                    >
                        <PlaceholderSkeleton
                            className="h-[17px] w-[120px] max-w-[100%]"
                            variant="rectangular"
                            animation="wave"
                        />
                        <div className="flex flex-row items-start space-x-[12px] pt-[8px]">
                            <div className="flex-1">
                                <PlaceholderSkeleton
                                    className="h-[14px] w-[70%] max-w-[100%]"
                                    variant="rectangular"
                                    animation="wave"
                                />
                                <PlaceholderSkeleton
                                    className="mt-[5px] h-[14px] w-[40%] max-w-[100%]"
                                    variant="rectangular"
                                    animation="wave"
                                />
                                <PlaceholderSkeleton
                                    className="mt-[13px] h-[12px] w-[80px] max-w-[100%]"
                                    variant="rectangular"
                                    animation="wave"
                                />
                            </div>
                            <div>
                                <PlaceholderSkeleton
                                    className="mx-auto h-[15px] w-[34px]"
                                    variant="rectangular"
                                    animation="pulse"
                                />
                            </div>
                        </div>
                    </div>
                ))}
        </div>
    );
});

enum NotificationType {
    All = '',
    Announcement = 'announcement',
    Chapters = 'chapter',
    Unlock = 'wtu',
    News = 'post',
    Comments = 'comment',
    Review = 'review',
    ProductReview = 'product_review',
}

type NotificationCategory = {
    key: NotificationType;
    title: string;
    groups: NotificationType[] | undefined;
};

const notificationCategories: NotificationCategory[] = [
    { key: NotificationType.All, title: 'All', groups: undefined },
    {
        key: NotificationType.News,
        title: 'News',
        groups: [
            NotificationType.News,
            NotificationType.Announcement,
            NotificationType.Review,
            NotificationType.ProductReview,
        ],
    },
    { key: NotificationType.Unlock, title: 'Unlock', groups: [NotificationType.Unlock] },
    { key: NotificationType.Chapters, title: 'Chapters', groups: [NotificationType.Chapters] },
    { key: NotificationType.Comments, title: 'Comments', groups: [NotificationType.Comments] },
];
//#endregion: Global constants and types

//#region : Main component

export function MarkAllAsReadTxt() {
    const { user } = useAuth();
    const { markAllRead } = useMarkAllRead();

    const handleMarkAllAsRead = useCallback(
        (e: React.MouseEvent) => {
            e.stopPropagation();
            e.preventDefault();

            markAllRead(user?.id);
        },
        [user?.id, markAllRead]
    );

    return (
        <Button
            onClick={handleMarkAllAsRead}
            variant="text"
            color="secondary"
            className="!text-[12px] font-semibold text-[#888] underline"
        >
            Mark all as read
        </Button>
    );
}

/**
 * Custom.
 *
 * Shows all notifications.
 * Notifications can be filtered by it's type.
 * Unread and archived notifications are displayed separately.
 */
export default function AllNotifications() {
    //#region : Variables, functions and api calls

    const [searchParams, setSearchParams] = useSearchParams();

    const paramPage = searchParams.get('page');
    const paramNotifType = searchParams.get('type');

    const [selectedNotifType, setSelectedNotifType] = useState(() => {
        return notificationCategories.find(c => c.key === (paramNotifType ?? ''))?.key ?? '';
    });

    const [page, setPage] = useState(() => {
        return paramPage ? Number(paramPage) : 1;
    });

    const [isPaging, setIsPaging] = useState(false);
    const noOfNotifsPerPage = 10;

    //#region : Server request to fetch user notifications
    const { user } = useAuth();
    const { notificationsResult, notificationsQuery } = useAllNotifications(
        user?.id,
        notificationCategories.find(c => c.key === selectedNotifType)?.groups ?? [],
        page,
        noOfNotifsPerPage
    );

    const { items: notifications = [] } = notificationsResult ?? {
        items: [],
    };

    //#endregion : Server request to fetch user notifications

    //#region: Handle pagination
    const totalPages = useMemo(() => {
        return Math.ceil((notificationsResult?.pageInfo?.total || 0) / noOfNotifsPerPage);
    }, [notificationsResult?.pageInfo?.total, noOfNotifsPerPage]);

    const setQueryString = useCallback((newPage: number | undefined, newType: string) => {
        const params = new URLSearchParams();

        if (newPage !== undefined) {
            params.set('page', newPage.toString());
        }

        const type = notificationCategories.find(c => c.key === (newType ?? ''))?.key ?? '';
        if (type !== '') {
            params.set('type', type);
        }

        return new URLSearchParams(params);
    }, []);

    const handlePagination = useCallback(
        async (e, page: number) => {
            batch(() => {
                setSearchParams(setQueryString(page, selectedNotifType));
                setPage(page);
                setIsPaging(true);
            });

            window.scrollTo({
                top: 0,
                behavior: 'smooth',
            });
        },
        [selectedNotifType, setQueryString, setSearchParams]
    );

    useEffect(() => {
        setIsPaging(prev => (prev ? false : prev));
    }, [notificationsResult]);
    //#endregion: Handle pagination

    //#region : Fitler notifications

    /** Custom. Handles notification filter change. */
    const handleNotifCategoryChange = useCallback(
        (notifCategory: string) => {
            batch(() => {
                //reset pagination to first page when user filters
                setPage(1);
                setIsPaging(true);
                setSelectedNotifType(notifCategory);
                setSearchParams(setQueryString(undefined, notifCategory));
            });
        },
        [setQueryString, setSearchParams]
    );
    //#endregion : Fitler notifications

    const showPlaceholders = (isPaging && notificationsQuery.isFetching) || !notificationsResult;

    //#endregion : Variables, functions and api calls

    return (
        <React.Fragment>
            <Grid container spacing={2} justifyContent="space-between" alignItems="flex-start">
                <Grid
                    item
                    display="flex"
                    flexGrow="1"
                    flexDirection="row"
                    justifyContent="space-between"
                    alignItems="center"
                >
                    <Typography className="mx-0 mt-[3px] mb-[24px]" variant="h2">
                        Notifications
                    </Typography>
                    <MarkAllAsReadTxt />
                </Grid>
            </Grid>
            <div>
                <ChipsSwiper gap={8}>
                    {notificationCategories.map((notifCategory, idx) => (
                        <SwiperSlide key={notifCategory.key} className="!w-auto mb-[24px]">
                            <Chips.Chip
                                label={notifCategory.title}
                                onClick={() => {
                                    handleNotifCategoryChange(notifCategory.key);
                                }}
                                selected={selectedNotifType === notifCategory.key}
                                className="font-set-sb13"
                            />
                        </SwiperSlide>
                    ))}
                </ChipsSwiper>
            </div>
            <div>
                {showPlaceholders && <PlaceholderComponent count={noOfNotifsPerPage} />}

                {!showPlaceholders && (
                    <>
                        {notifications?.map(notif => (
                            <UserNotification key={notif.id} notification={notif} />
                        ))}

                        {notificationsResult && notifications?.length == 0 && (
                            <div className="py-[20px]">No notifications</div>
                        )}
                    </>
                )}
            </div>

            {totalPages > 0 && (
                <div className="py-[20px]">
                    <Paging count={totalPages} page={page} onChange={handlePagination} />
                </div>
            )}
        </React.Fragment>
    );
}

//#endregion : Main component
