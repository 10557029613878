import type PostHog from 'posthog-js';
import type { PostHogConfig } from 'posthog-js';
import type { AnalyticsPayment, AnalyticsPluginInstance, AnalyticsUserInfo } from './types';

type PosthogClient = typeof PostHog & {};

type PosthogPluginConfig = Pick<PostHogConfig, 'api_host' | 'autocapture'> & {
    token: string;
};

export type PosthogPluginType = AnalyticsPluginInstance<PosthogClient, PosthogPluginConfig>;

type ReadyHandler = (instance: PosthogClient) => void;

export class PosthogPlugin implements AnalyticsPluginInstance<PosthogClient, PosthogPluginConfig> {
    private _readyHandlers: ReadyHandler[] = [];
    private instance: Promise<PosthogClient>;

    init({ token, ...config }: PosthogPluginConfig) {
        if (!token) {
            throw new Error('Missing Posthog token');
        }
        if (!config.api_host) {
            throw new Error('Missing Posthog API key');
        }

        this.instance = new Promise((resolve, reject) => {
            (async () => {
                try {
                    const posthog = !process.env.SSR ? (await import('posthog-js')).posthog : null;

                    if (!posthog) {
                        reject('ssr');

                        return;
                    }

                    const instance = posthog.init(
                        token,
                        {
                            api_host: config.api_host,
                            autocapture: config.autocapture,
                            loaded: this.readyHandler,
                        },
                        undefined
                    );

                    if (!instance) {
                        return;
                    }

                    resolve(posthog);
                } catch (e) {
                    reject(e);
                }
            })();
        });
    }

    onReady(action: (instance: PosthogClient) => void): void {
        this._readyHandlers.push(action);
    }

    getInstance(): Promise<PosthogClient> {
        return this.instance;
    }

    async setUser(userId: string, info: AnalyticsUserInfo | null) {
        const instance = await this.getInstance();

        instance.identify(userId || undefined);

        if (userId) {
            const propertiesToSet = {};

            for (const [key, value] of Object.entries(info || {})) {
                if (value instanceof Date) {
                    propertiesToSet[key] = value.toISOString();
                } else {
                    propertiesToSet[key] = value;
                }
            }
            instance.setPersonProperties(propertiesToSet);
        } else {
            instance.reset(true);
        }
    }

    async trackEvent(eventName: string, properties?: Record<string, any>) {
        (await this.getInstance()).capture(eventName, properties);
    }

    async trackPayment(payment: AnalyticsPayment) {
        (await this.getInstance()).capture('purchase', {
            transactionId: payment.transactionId,
            productId: payment.productId,
            price: payment.price,
            quantity: payment.quantity,
            items: payment.items,
        });
    }

    private readyHandler = async () => {
        const instance = await this.getInstance();

        for (const handler of this._readyHandlers) {
            handler(instance);
        }
    };
}
