import { type AllEvents, posthogHandler } from '@app/analytics/handlers';
import type { ComponentType, ReactNode } from 'react';

export default function withPosthogReport(Component: ComponentType & { children?: ReactNode }) {
    const Observer = (args: any) => {
        const { children, ...rest } = args;
        return (
            <div
                onClick={e => {
                    const posthogTarget: HTMLElement | null = (e.target as HTMLElement).closest(
                        '[data-posthog-click-event]'
                    );
                    if (!posthogTarget) return;
                    const posthogEvent = posthogTarget?.getAttribute('data-posthog-click-event') as AllEvents;
                    posthogHandler(posthogEvent, posthogTarget);
                }}
            >
                <Component {...rest}>{children}</Component>
            </div>
        );
    };
    return Observer;
}
