import { BookmarkEvents } from '@app/domains/bookmark/analytics/posthog/events';
import { bookmarkAnalyticsFactory } from '@app/domains/bookmark/analytics/posthog/handlers';
import { ChampionEvents } from '@app/domains/champion/analytics/posthog/events';
import { championAnalyticsFactory, championPosthogHandler } from '@app/domains/champion/analytics/posthog/handlers';
import { ChapterEvents } from '@app/domains/chapter/analytics/posthog/events';
import { chapterAnalyticsFactory, chapterPosthogHandler } from '@app/domains/chapter/analytics/posthog/handlers';
import { HeaderEvents } from '@app/domains/header/analytics/posthog/events';
import { headerPosthogHandler } from '@app/domains/header/analytics/posthog/handlers';
import { HomeEvents } from '@app/domains/home/analytics/posthog/events';
import { homePosthogHandler } from '@app/domains/home/analytics/posthog/handlers';
import { NovelEvents } from '@app/domains/novel/analytics/posthog/events';
import { novelAnalyticsFactory, novelPosthogHandler } from '@app/domains/novel/analytics/posthog/handlers';
import { StoreEvents } from '@app/domains/store/analytics/posthog/events';
import { storeAnalyticsFactory } from '@app/domains/store/analytics/posthog/handlers';

export type AllEvents =
    | HomeEvents
    | HeaderEvents
    | BookmarkEvents
    | NovelEvents
    | ChapterEvents
    | StoreEvents
    | ChampionEvents;

export const analyticsFactory = (event: AllEvents): any => {
    if (Object.values(BookmarkEvents).includes(event as BookmarkEvents)) {
        return bookmarkAnalyticsFactory(event as BookmarkEvents);
    }
    if (Object.values(ChapterEvents).includes(event as ChapterEvents)) {
        return chapterAnalyticsFactory(event as ChapterEvents);
    }
    if (Object.values(NovelEvents).includes(event as NovelEvents)) {
        return novelAnalyticsFactory(event as NovelEvents);
    }
    if (Object.values(StoreEvents).includes(event as StoreEvents)) {
        return storeAnalyticsFactory(event as StoreEvents);
    }
    if (Object.values(ChampionEvents).includes(event as ChampionEvents)) {
        return championAnalyticsFactory(event as ChampionEvents);
    }
    return () => {}; // fallback
};

export const posthogHandler = (event: AllEvents, el: HTMLElement | undefined) => {
    if (Object.values(HomeEvents).includes(event as HomeEvents)) {
        return homePosthogHandler(event as HomeEvents, el);
    }
    if (Object.values(HeaderEvents).includes(event as HeaderEvents)) {
        return headerPosthogHandler(event as HeaderEvents);
    }
    if (Object.values(ChapterEvents).includes(event as ChapterEvents)) {
        return chapterPosthogHandler(event as ChapterEvents, el);
    }
    if (Object.values(ChampionEvents).includes(event as ChampionEvents)) {
        return championPosthogHandler(event as ChampionEvents, el);
    }
    if (Object.values(NovelEvents).includes(event as NovelEvents)) {
        return novelPosthogHandler(event as NovelEvents, el);
    }
    return () => {}; // fallback
};
