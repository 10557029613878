import { ChipsContext } from '@app/domains/common/hooks/useChips';
import type { PropsWithChildren } from 'react';
import { FreeMode } from 'swiper';
import { Swiper } from 'swiper/react';

export default function ChipsSwiper({ children, gap = 8 }: PropsWithChildren<{ gap: number }>) {
    return (
        <ChipsContext.Provider value={{ gap }}>
            <Swiper
                modules={[FreeMode]}
                freeMode={{
                    enabled: true,
                    momentumRatio: 1,
                }}
                threshold={10}
                slidesPerView="auto"
                className="-mt-8 !-ml-8 !overflow-visible"
                style={{ marginTop: `-${gap}px`, marginLeft: `-${gap}px !important` }}
            >
                {children}
            </Swiper>
        </ChipsContext.Provider>
    );
}
