// @generated by protobuf-ts 2.9.4 with parameter optimize_code_size,long_type_number,generate_dependencies
// @generated from protobuf file "Protos/users.proto" (package "wuxiaworld.api.v2", syntax proto3)
// tslint:disable
import { Empty } from "../google/protobuf/empty";
import { ServiceType } from "@protobuf-ts/runtime-rpc";
import { MessageType } from "@protobuf-ts/runtime";
import { VipItem } from "./vips";
import { Timestamp } from "../google/protobuf/timestamp";
import { StringValue } from "../google/protobuf/wrappers";
import { UnlockedVipNovelInfo } from "./vips";
import { ObjectItem } from "./objects";
import { Role } from "./roles";
import { BoolValue } from "../google/protobuf/wrappers";
/**
 * @generated from protobuf message wuxiaworld.api.v2.GetMyUserResponse
 */
export interface GetMyUserResponse {
    /**
     * @generated from protobuf field: wuxiaworld.api.v2.UserItem item = 1;
     */
    item?: UserItem;
}
/**
 * @generated from protobuf message wuxiaworld.api.v2.SetAvatarRequest
 */
export interface SetAvatarRequest {
    /**
     * @generated from protobuf field: bytes image = 1;
     */
    image: Uint8Array;
}
/**
 * @generated from protobuf message wuxiaworld.api.v2.SetAvatarChunkedRequest
 */
export interface SetAvatarChunkedRequest {
    /**
     * @generated from protobuf field: bytes chunk = 2;
     */
    chunk: Uint8Array;
}
/**
 * @generated from protobuf message wuxiaworld.api.v2.DeleteAccountRequest
 */
export interface DeleteAccountRequest {
    /**
     * @generated from protobuf field: bool confirm = 1;
     */
    confirm: boolean;
}
/**
 * @generated from protobuf message wuxiaworld.api.v2.DeleteAccountResponse
 */
export interface DeleteAccountResponse {
    /**
     * @generated from protobuf field: bool result = 1;
     */
    result: boolean;
}
/**
 * @generated from protobuf message wuxiaworld.api.v2.UpdateUserSettingsRequest
 */
export interface UpdateUserSettingsRequest {
    /**
     * @generated from protobuf field: google.protobuf.BoolValue autoUnlockChapters = 1;
     */
    autoUnlockChapters?: BoolValue;
    /**
     * @generated from protobuf field: google.protobuf.BoolValue allowMarketing = 2;
     */
    allowMarketing?: BoolValue;
    /**
     * @generated from protobuf field: google.protobuf.BoolValue isParagraphCommentsEnabled = 3;
     */
    isParagraphCommentsEnabled?: BoolValue;
}
/**
 * @generated from protobuf message wuxiaworld.api.v2.UserSettings
 */
export interface UserSettings {
    /**
     * @generated from protobuf field: bool autoUnlockChapters = 1;
     */
    autoUnlockChapters: boolean;
    /**
     * @generated from protobuf field: bool is2faEnabled = 2 [json_name = "is2faEnabled"];
     */
    is2FaEnabled: boolean;
    /**
     * @generated from protobuf field: bool allowMarketing = 3;
     */
    allowMarketing: boolean;
    /**
     * @generated from protobuf field: bool isParagraphCommentsEnabled = 4;
     */
    isParagraphCommentsEnabled: boolean;
}
/**
 * @generated from protobuf message wuxiaworld.api.v2.UserPermissions
 */
export interface UserPermissions {
    /**
     * @generated from protobuf field: repeated wuxiaworld.api.v2.Role roles = 1;
     */
    roles: Role[];
    /**
     * @generated from protobuf field: repeated wuxiaworld.api.v2.ObjectItem objects = 2;
     */
    objects: ObjectItem[];
}
/**
 * @generated from protobuf message wuxiaworld.api.v2.UserSponsorInfo
 */
export interface UserSponsorInfo {
    /**
     * @generated from protobuf field: int32 totalCount = 1;
     */
    totalCount: number;
    /**
     * @generated from protobuf field: int32 activeCount = 2;
     */
    activeCount: number;
}
/**
 * @generated from protobuf message wuxiaworld.api.v2.UserVipInfo
 */
export interface UserVipInfo {
    /**
     * @generated from protobuf field: repeated wuxiaworld.api.v2.UnlockedVipNovelInfo unlockedNovels = 1;
     */
    unlockedNovels: UnlockedVipNovelInfo[];
}
/**
 * @generated from protobuf message wuxiaworld.api.v2.UserItem
 */
export interface UserItem {
    /**
     * @generated from protobuf field: string id = 1;
     */
    id: string;
    /**
     * @generated from protobuf field: string userName = 2;
     */
    userName: string;
    /**
     * @generated from protobuf field: google.protobuf.StringValue avatarUrl = 3;
     */
    avatarUrl?: StringValue;
    /**
     * @generated from protobuf field: google.protobuf.StringValue email = 4;
     */
    email?: StringValue;
    /**
     * @generated from protobuf field: google.protobuf.Timestamp joinDate = 5;
     */
    joinDate?: Timestamp;
    /**
     * @generated from protobuf field: bool isVipActive = 6;
     */
    isVipActive: boolean;
    /**
     * @generated from protobuf field: wuxiaworld.api.v2.VipItem vip = 7;
     */
    vip?: VipItem;
    /**
     * @generated from protobuf field: google.protobuf.StringValue flair = 8;
     */
    flair?: StringValue;
    /**
     * @generated from protobuf field: wuxiaworld.api.v2.UserSettings settings = 9;
     */
    settings?: UserSettings;
    /**
     * @generated from protobuf field: wuxiaworld.api.v2.UserPermissions permissions = 10;
     */
    permissions?: UserPermissions;
    /**
     * @generated from protobuf field: bool isLegacy = 11;
     */
    isLegacy: boolean;
    /**
     * @generated from protobuf field: bool isLegacyVip = 12;
     */
    isLegacyVip: boolean;
    /**
     * @generated from protobuf field: bool isEmailVerified = 13;
     */
    isEmailVerified: boolean;
    /**
     * @generated from protobuf field: wuxiaworld.api.v2.UserSponsorInfo sponsorInfo = 14;
     */
    sponsorInfo?: UserSponsorInfo;
    /**
     * @generated from protobuf field: wuxiaworld.api.v2.UserVipInfo vipInfo = 15;
     */
    vipInfo?: UserVipInfo;
}
// @generated message type with reflection information, may provide speed optimized methods
class GetMyUserResponse$Type extends MessageType<GetMyUserResponse> {
    constructor() {
        super("wuxiaworld.api.v2.GetMyUserResponse", [
            { no: 1, name: "item", kind: "message", T: () => UserItem }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message wuxiaworld.api.v2.GetMyUserResponse
 */
export const GetMyUserResponse = new GetMyUserResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SetAvatarRequest$Type extends MessageType<SetAvatarRequest> {
    constructor() {
        super("wuxiaworld.api.v2.SetAvatarRequest", [
            { no: 1, name: "image", kind: "scalar", T: 12 /*ScalarType.BYTES*/ }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message wuxiaworld.api.v2.SetAvatarRequest
 */
export const SetAvatarRequest = new SetAvatarRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SetAvatarChunkedRequest$Type extends MessageType<SetAvatarChunkedRequest> {
    constructor() {
        super("wuxiaworld.api.v2.SetAvatarChunkedRequest", [
            { no: 2, name: "chunk", kind: "scalar", T: 12 /*ScalarType.BYTES*/ }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message wuxiaworld.api.v2.SetAvatarChunkedRequest
 */
export const SetAvatarChunkedRequest = new SetAvatarChunkedRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class DeleteAccountRequest$Type extends MessageType<DeleteAccountRequest> {
    constructor() {
        super("wuxiaworld.api.v2.DeleteAccountRequest", [
            { no: 1, name: "confirm", kind: "scalar", T: 8 /*ScalarType.BOOL*/ }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message wuxiaworld.api.v2.DeleteAccountRequest
 */
export const DeleteAccountRequest = new DeleteAccountRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class DeleteAccountResponse$Type extends MessageType<DeleteAccountResponse> {
    constructor() {
        super("wuxiaworld.api.v2.DeleteAccountResponse", [
            { no: 1, name: "result", kind: "scalar", T: 8 /*ScalarType.BOOL*/ }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message wuxiaworld.api.v2.DeleteAccountResponse
 */
export const DeleteAccountResponse = new DeleteAccountResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class UpdateUserSettingsRequest$Type extends MessageType<UpdateUserSettingsRequest> {
    constructor() {
        super("wuxiaworld.api.v2.UpdateUserSettingsRequest", [
            { no: 1, name: "autoUnlockChapters", kind: "message", T: () => BoolValue },
            { no: 2, name: "allowMarketing", kind: "message", T: () => BoolValue },
            { no: 3, name: "isParagraphCommentsEnabled", kind: "message", T: () => BoolValue }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message wuxiaworld.api.v2.UpdateUserSettingsRequest
 */
export const UpdateUserSettingsRequest = new UpdateUserSettingsRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class UserSettings$Type extends MessageType<UserSettings> {
    constructor() {
        super("wuxiaworld.api.v2.UserSettings", [
            { no: 1, name: "autoUnlockChapters", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 2, name: "is2faEnabled", kind: "scalar", jsonName: "is2faEnabled", T: 8 /*ScalarType.BOOL*/ },
            { no: 3, name: "allowMarketing", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 4, name: "isParagraphCommentsEnabled", kind: "scalar", T: 8 /*ScalarType.BOOL*/ }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message wuxiaworld.api.v2.UserSettings
 */
export const UserSettings = new UserSettings$Type();
// @generated message type with reflection information, may provide speed optimized methods
class UserPermissions$Type extends MessageType<UserPermissions> {
    constructor() {
        super("wuxiaworld.api.v2.UserPermissions", [
            { no: 1, name: "roles", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => Role },
            { no: 2, name: "objects", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => ObjectItem }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message wuxiaworld.api.v2.UserPermissions
 */
export const UserPermissions = new UserPermissions$Type();
// @generated message type with reflection information, may provide speed optimized methods
class UserSponsorInfo$Type extends MessageType<UserSponsorInfo> {
    constructor() {
        super("wuxiaworld.api.v2.UserSponsorInfo", [
            { no: 1, name: "totalCount", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 2, name: "activeCount", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message wuxiaworld.api.v2.UserSponsorInfo
 */
export const UserSponsorInfo = new UserSponsorInfo$Type();
// @generated message type with reflection information, may provide speed optimized methods
class UserVipInfo$Type extends MessageType<UserVipInfo> {
    constructor() {
        super("wuxiaworld.api.v2.UserVipInfo", [
            { no: 1, name: "unlockedNovels", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => UnlockedVipNovelInfo }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message wuxiaworld.api.v2.UserVipInfo
 */
export const UserVipInfo = new UserVipInfo$Type();
// @generated message type with reflection information, may provide speed optimized methods
class UserItem$Type extends MessageType<UserItem> {
    constructor() {
        super("wuxiaworld.api.v2.UserItem", [
            { no: 1, name: "id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "userName", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "avatarUrl", kind: "message", T: () => StringValue },
            { no: 4, name: "email", kind: "message", T: () => StringValue },
            { no: 5, name: "joinDate", kind: "message", T: () => Timestamp },
            { no: 6, name: "isVipActive", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 7, name: "vip", kind: "message", T: () => VipItem },
            { no: 8, name: "flair", kind: "message", T: () => StringValue },
            { no: 9, name: "settings", kind: "message", T: () => UserSettings },
            { no: 10, name: "permissions", kind: "message", T: () => UserPermissions },
            { no: 11, name: "isLegacy", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 12, name: "isLegacyVip", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 13, name: "isEmailVerified", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 14, name: "sponsorInfo", kind: "message", T: () => UserSponsorInfo },
            { no: 15, name: "vipInfo", kind: "message", T: () => UserVipInfo }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message wuxiaworld.api.v2.UserItem
 */
export const UserItem = new UserItem$Type();
/**
 * @generated ServiceType for protobuf service wuxiaworld.api.v2.Users
 */
export const Users = new ServiceType("wuxiaworld.api.v2.Users", [
    { name: "GetMyUser", options: {}, I: Empty, O: GetMyUserResponse },
    { name: "UpdateUserSettings", options: {}, I: UpdateUserSettingsRequest, O: Empty },
    { name: "SetAvatar", options: {}, I: SetAvatarRequest, O: StringValue },
    { name: "SetAvatarChunked", clientStreaming: true, options: {}, I: SetAvatarChunkedRequest, O: Empty },
    { name: "DeleteAvatar", options: {}, I: Empty, O: Empty },
    { name: "DeleteAccount", options: {}, I: DeleteAccountRequest, O: DeleteAccountResponse }
]);
